import http from "./http-common";

class PlayersListService {

    getListUsers(data) {
        return http.post(`/GetUsers`, data);
    }
    getUserDetails(data) {
        return http.post(`/GetUserData`, data);
    }

    getPlayers(data) {
        return http.post(`/GetPlayers`, data);
    }
    getPlayCheck(data) {
        return http.post(`/GetPlayCheck`, data);
    }
    getCashiers(data) {
        return http.post(`/GetCashiers`, data);
    }
    getAgents(data) {
        return http.post(`/GetAgents`, data);
    }
    getMasterAgents(data) {
        return http.post(`/GetMasterAgents`, data);
    }
    AddCredit(data) {
        return http.post(`/addCredit`, data);
    }
    WithdrawCredit(data) {
        return http.post(`/withdrawCredit`, data);
    }
}

export default new PlayersListService();