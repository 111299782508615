import React from "react";
const LocationList = (props) => {
return (
    props.ips.map((val, idx)=> {
    var myDate = new Date(props.ips[idx].Ingreso);
    return (
        <tr>
            <td>{props.ips[idx].userName}</td>
            <td>{props.ips[idx].ip}</td>
            <td>{props.ips[idx].country}</td>
            <td>{props.ips[idx].city}</td>
            <td>{myDate.toLocaleString('es-MX')}</td>

        </tr>
        )
    })
)
}
export default LocationList;