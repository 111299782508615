
import http from "./http-ips";

class IPService {

    getIP() {
        return http.get(`/`);
    }
    
}

export default new IPService();
