import React from "react";
const ListUser = (props) => {
return (
    props.users.map((val, idx)=> {
    //let urlId = `url-${idx}`;
    if(localStorage.getItem('languagePage')=== 'es'){
        return (
            <tr>
                <td>{props.users[idx].userName}</td>
                <td>{props.users[idx].email}</td>
                <td>{props.users[idx].name} {props.users[idx].lastName}</td>
                <td>{props.users[idx].Utype}</td>
                <td><a href={"/AdminModules/Player/"+props.users[idx]._id}>Ver Usuario</a></td>
            </tr>
            )
    }else{
        return (
            <tr>
                <td>{props.users[idx].userName}</td>
                <td>{props.users[idx].email}</td>
                <td>{props.users[idx].name} {props.users[idx].lastName}</td>
                <td>{props.users[idx].Utype}</td>
                <td><a href={"/AdminModules/Player/"+props.users[idx]._id}>User Information</a></td>
            </tr>
            )
    }
    })
)
}
export default ListUser;