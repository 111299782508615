import React, {Component} from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import breadcrumb from '../../Complements/img/breadcrumb.png'
//import GameRow from '../../Components/GameRow/GameRow';
import listGamesService from '../../Services/list.games.service';
import preloader from "../../Images/preloader.gif"

import GameService from '../../Services/game.service';
import Swal from 'sweetalert2'
import GameRow from '../../Components/GameRow/GameRow';
import Footer from '../../Components/Footer/Footer';
import ProfileService from '../../Services/profile.service';
import  './Games.css';

class Games extends Component {

    state={
        user:{
            userName:'',
            password:'',
            passwordconfirm: '',
            passwordnew:'',
            email:'',
            name:'',
            lastName:'',
            direction1:'',
            direction2:'',
            city:'',
            pc:'',
            state:'',
            phone:'',
            credits:'0'
        },
        gamelist:[{}],
        categorylist:[{}],
        currentCategory: "All Games"
    }

    getProfileInfo = () => {
        let data ={
            usertoken: localStorage.getItem('token')
        }
        let {user} = this.state;
        ProfileService.getProfile(data).then(response =>{
            if(response.status === 201){
                user.userName = response.data.userName
                user.email = response.data.email
                user.name = response.data.name
                user.lastName = response.data.lastName
                user.direction1 = response.data.direction1
                user.direction2 = response.data.direction2
                user.city = response.data.city
                user.pc = response.data.pc
                user.state = response.data.state
                user.phone = response.data.phone
                user.credits = response.data.credits
                this.setState({ user });
            }else{

            }
        })
    }
    getGames = () =>{
        let gamelist = this.state;
        var data={}
        listGamesService.getListGames(data).then(response => {
            if(response.status === 200){
                gamelist = response.data.games;
                this.setState({ gamelist });
            }
        }).catch(e => {
            //pass
        });
    }
    getCategories = () =>{
        let categorylist = this.state;
        var data={}
        listGamesService.getListCategories(data).then(response => {
            if(response.status === 200){
                categorylist = response.data.categories;
                categorylist.splice(2, 1)
                categorylist.splice(4, 0, {gameCategoryName: 'Interactive Games'})
                categorylist.splice(5, 1);
                this.setState({ categorylist });
                document.getElementById('preloader').style.display="none"
            }
        }).catch(e => {
            //pass
        });
    }
    handleChange = (e) =>{
        var stringvarar = e.target.value;
        var searchGames = document.getElementById("gamelist").children;
        for(var i = 0; i < searchGames.length; i++) {
            if(searchGames[i].id === ''){
              //pass
            }else if(searchGames[i].id === ''){
                document.getElementById(searchGames[i].id).style.display=""
            }else if(searchGames[i].id.toLowerCase().includes(stringvarar.toLowerCase())) {
                document.getElementById(searchGames[i].id).style.display=""
            }else{
                document.getElementById(searchGames[i].id).style.display="none"
            }
        }
    }
    filter = (e) =>{
        var stringvarar = e.target.id;

        var searchGames = document.getElementById("gamelist").children;
        for(var i = 0; i < searchGames.length; i++) {
            if(stringvarar === ''){
                document.getElementById(searchGames[i].children[0].id).style.display=""
            }else if(searchGames[i].className.toLowerCase() === (stringvarar.toLowerCase())) {
                document.getElementById(searchGames[i].children[0].id).style.display=""
            }else{
                document.getElementById(searchGames[i].children[0].id).style.display="none"
            }
        }
    }
    filterCategory = (e) =>{
        var category = e.target.id;
        var searchGames = document.getElementById("gamelist").children;
        let {currentCategory} = this.state
        for(var i = 0; i < searchGames.length; i++) {
            if(category === ''){
                document.getElementById(searchGames[i].id).style.display=""
            }else if(searchGames[i].className.toLowerCase().includes(category.toLowerCase())) {
                document.getElementById(searchGames[i].id).style.display=""
            }else{
                document.getElementById(searchGames[i].id).style.display="none"
            }
        }
        currentCategory = e.target.textContent
        this.setState({currentCategory})

    }

    CallGame = (e) =>{

        var data = {
            userName: localStorage.getItem('userName'),
            token:localStorage.getItem('token'),
            game: e.target.name
        }
        GameService.GetGame(data).then(response => {
            if(response.status === 201){
                localStorage.setItem('game', response.data.url)
                window.location.href = "https://www.intergaming.com/game"
            }else if(response.status === 200){
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        }).catch(e => {
            console.log(e)
            Swal.fire({
                title: 'Error',
                text: 'Verifica tus credenciales',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        });
    }

    componentDidMount(){
        this.getGames();
        this.getCategories()
    }
render(){
    var {user} = this.state;
    if(user.userName.length === 0){
        this.getProfileInfo()
    }
    let {gamelist} = this.state
    let {categorylist} = this.state

    return (
    <body data-spy="scroll" data-target="#navbar" data-offset="0">
        <div class="preloader" id="preloader">
            <img src={preloader} alt=""/>
        </div>
        <NavBar className="sticky-top" />
        <div class="breadcrumb-miscoo">
            <div class="breadcrumb-img">
                <img src={breadcrumb} alt=""/>
                <img src={breadcrumb} alt=""/>
                <img src={breadcrumb} alt=""/>
                <img src={breadcrumb} alt=""/>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-6">
                        <div class="breadcrumb-content">
                            <h2 class="title">Nuestros juegos</h2>
                            <ul>
                                <li>
                                    <a href="/">Lobby</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row money-mobile ">
            <div className="col-12">
                <br />
                <br />
                <h5 className='welcome-tag'>    Bienvenido {this.state.user.name} Tu crédito es de: {parseInt(this.state.user.credits).toFixed(2)}</h5>
            </div>
        </div>
        <br />
        <br />
        <br />
        <div class="row">
            <div class="col-xl-1 col-lg-1 col-md-1">
            </div>
            <div className="col-xl-10 col-lg-10 col-md-10">
                <div class="scrollmenu">
                    <ul id="scroll">
                        <li class="nav-item" onClick={this.filterCategory} id="newGames">
                            <a class="a-horizontal" onClick={this.filterCategory}>
                                <span class="text" id="newGames">Juegos Nuevos</span>
                            </a>
                        </li>
                        <li class="nav-item" onClick={this.filterCategory} id="popular">
                            <a class="a-horizontal" onClick={this.filterCategory}>
                                <span class="text" id="popular">Populares</span>
                            </a>
                        </li>
                        <li class="nav-item" onClick={this.filterCategory} id="linkwin">
                            <a class="a-horizontal" onClick={this.filterCategory}>
                                <span class="text" id="linkwin">Link & Win</span>
                            </a>
                        </li>
                        {
                            categorylist.map((val, idx)=> {
                                return (
                                    <li class="nav-item" onClick={this.filterCategory} id={categorylist[idx].gameCategoryName}>
                                        <a class="a-horizontal">
                                            <span class="text" id={categorylist[idx].gameCategoryName}>{categorylist[idx].gameCategoryName}</span>
                                        </a>
                                    </li>
                                );

                            })
                        }                    
                    </ul>
                </div>
            </div>

        </div>
<div class="games game-page">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="all-games anim-change">
                    <br /><br />

                    <div class="title-cover">
                        <div class="row">
                            <div className="col-xl-2 col-lg-2 col-md-2">
                                <h4 class="games-title">{this.state.currentCategory}</h4>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3">
                                <form class="part-searchBar" onChange={this.handleChange}>
                                    <input style={{background:'#212529', border:'none', height:'3rem', fontSize:'1rem', width:'15rem', color:'white'}} type="text" placeholder="Buscar juego por nombre"/>
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                    </svg>
                                </form>  
                            </div>
                        </div>

                    </div>
                   
                    <br/>
                    <br/>
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="all-games anim-change">
                            <div id='gamelist' className='row'>
                                <GameRow gamelist={gamelist}/>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>
    <Footer />
    </body>
    );
}
}
export default Games;