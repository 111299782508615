import axios from "axios";

var agent = "";
if (localStorage.getItem("agent") === null || localStorage.getItem("agent") === undefined) {
    agent = "api_agent"
} else {
    agent = localStorage.getItem("agent")
}

export default axios.create({
    baseURL: "https://www.intergaming.com/api/",
    headers: {
        "Content-type": "application/json",
        "agent": agent
    }
})