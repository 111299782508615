import React from "react";
import Swal from "sweetalert2";
import GameService from "../../Services/game.service";
import image1 from '../../Complements/img/games/assets-1.jpg'
import image2 from './artes/Thunderstruck.png'


function importAll(r) {
    return r.keys().map(r);
}

function returnImage(name) {
    if (name !== undefined) {
        name = name.replaceAll(/\s/g, '');
        name = name.replaceAll('_', '');
        name = name.replaceAll('-', '');
        name = name.replaceAll("'", '');
        name = name.replaceAll(":", '');
        name = name.replaceAll("(Jade)", '');
        name = name.replaceAll("(", '');
        name = name.replaceAll(")", '');
        name = name.replaceAll(" ", '');
        name = name.replaceAll("™", '');
        name = name.replaceAll("®", '');
        name = name.replaceAll("!", '');
        name = name.replaceAll("v90", '');
        name = name.replaceAll("btn200x200", '');

        const images = importAll(require.context('./artes/', false, /\.(png|jpe?g|svg)$/));
        for (let index = 0; index < images.length; index++) {
            const element = images[index];
            var elementName = element.default.toLowerCase();
            elementName = elementName.replaceAll('_', '');
            elementName = elementName.replaceAll(' ', '');
            elementName = elementName.replaceAll('-', '');
            elementName = elementName.replaceAll(':', '');
            elementName = elementName.replaceAll("v90", '');
            elementName = elementName.replaceAll("btn200x200", '');

            if(name === "Thunderstruck"){
                return image2
            }

            if (elementName.includes(name.toLowerCase())) {
                return element.default;
            }
            
        }
    }

    return image1;
}



const GameRow = (props) => {
    const list1 = ["4 Diamond Blues™ - Megaways™", "5 Star Knockout", "Africa X UP™", "Agent Jane Blonde Max Volume", "Amazing Link™ Apollo", "Ancient Fortunes: Poseidon Megaways ™", "Artic Enchantress: Link & Win", "Atlantis - The Forgotten Kingdom", "Aztec Falls", "Big Boom Riches", "Book Of Captain Silver", "Book Of Mrs Claus", "Break Da Bank Again™ MEGAWAYS™", "Bust The Mansion", "Cash 'N Riches Megaways™", "Cat Clans", "Cossacks: The Wild Hunt", "Hyper Gold", "Hyper Star", "Hyper Strike HyperSpins", "Jurassic Park Gold", "Jurassic World Raptor Riches", "Kodiak Kingdom", "Playboy Fortunes"];
    const list2 = ["Amazing Link Zeus", "Amazing Link Fates", "Jurassic Park Gold", "Bust The Mansion", "Amazing Link Riches", "Artic Enchantress: Link & Win", "Links of Ra: Link & Win", "Leprechaun Links: Link & Win", "WWE Legends: Link & Win", "Amazing Link Apollo", "Game of Thrones Power Stacks: Link & Win", "Hyper Gold","Thunderstruck Wild Lightning"]
    const list3 = ["Amazing Link Zeus", "Ancient Fortunes: Poseidon Megaways ™", "Thunderstruck", "Blazing Mammoth", "Queen of Alexandria™", "Isis", "Ladies Nite", "9 Masks Of Fire", "10000 Wishes", "Ancient Fortunes: Zeus", "Spring Break", "Deck the Halls", "Centre Court", "Break da Bank Again Respin ", "Lucky Riches Hyperspins", "Fire Forge", "Book of King Arthur", "Hyper Strike™", "Wanted Outlaws", "Avalon", "Joyful Joker Megaways"]

    const CallGame = (e) => {

        var data = {
            userName: localStorage.getItem('userName'),
            token: localStorage.getItem('token'),
            game: e.target.name
        }
        GameService.GetGame(data).then(response => {
            if (response.status === 201) {
                localStorage.setItem('game', response.data.url)
                window.location.href = "https://www.intergaming.com/game"
            } else if (response.status === 200) {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        }).catch(e => {
            console.log(e)
            localStorage.clear();
            Swal.fire({
                title: 'Error',
                text: 'Verifica tus credenciales',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            window.location.reload();
        });
    }
    return (
        props.gamelist.map((val, idx) => {
            //let gameId = `game-${idx}`;
            let gamename = props.gamelist[idx].gameName;
            if (list1.includes(gamename) && list2.includes(gamename)  && list3.includes(gamename)) {
                return ( <div id = { props.gamelist[idx].gameName }
                    className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 newgames linkwin popular' } >
                    <div className = 'single-game' >
                    <div class = "part-img" >

                    <img src = { returnImage(props.gamelist[idx].gameName) } alt = ""/>
                    < img className = "icon-img"
                    src = "assets/img/games/icon-1.png"
                    alt = "" />
                    </div> <div class = "part-text" >
                    <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                    name = { props.gamelist[idx].gameCode }
                    onClick = { CallGame } > Jugar </button> </div > </div> </div >
                )
            } else if (list1.includes(gamename) && list2.includes(gamename)) {
                return ( <div id = { props.gamelist[idx].gameName }
                    className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 newgames linkwin' } >
                    <div className = 'single-game' >
                    <div class = "part-img" >

                    <img src = { returnImage(props.gamelist[idx].gameName) } alt = ""/>
                    < img className = "icon-img"
                    src = "assets/img/games/icon-1.png"
                    alt = "" />
                    </div> <div class = "part-text" >
                    <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                    name = { props.gamelist[idx].gameCode }
                    onClick = { CallGame } > Jugar </button> </div > </div> </div >
                )
            } else if(list1.includes(gamename)) {
                return ( <div id = { props.gamelist[idx].gameName }
                    className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 newgames' } >
                    <div className = 'single-game' >
                    <div class = "part-img" >

                    <img src = { returnImage(props.gamelist[idx].gameName) } alt = ""/>
                    < img className = "icon-img"
                    src = "assets/img/games/icon-1.png"
                    alt = "" />
                    </div> <div class = "part-text" >
                    <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                    name = { props.gamelist[idx].gameCode }
                    onClick = { CallGame } > Jugar </button> </div > </div> </div >
                )
            } else if (list2.includes(gamename)) {
                return ( <div id = { props.gamelist[idx].gameName }
                    className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 linkwin' } >
                    <div className = 'single-game' >
                    <div class = "part-img" >

                    <img src = { returnImage(props.gamelist[idx].gameName) }alt = ""/>
                    < img className = "icon-img" src = "assets/img/games/icon-1.png" alt = ""/>
                    </div> <div class = "part-text" >
                    <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                    name = { props.gamelist[idx].gameCode }
                    onClick = { CallGame } > Jugar </button> </div > </div> </div >
                )
            } else if (list2.includes(gamename)  && list3.includes(gamename)) {
                return ( <div id = { props.gamelist[idx].gameName }
                    className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 linkwin popular' } >
                    <div className = 'single-game' >
                    <div class = "part-img" >

                    <img src = { returnImage(props.gamelist[idx].gameName) }alt = ""/>
                    < img className = "icon-img" src = "assets/img/games/icon-1.png" alt = ""/>
                    </div> <div class = "part-text" >
                    <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                    name = { props.gamelist[idx].gameCode }
                    onClick = { CallGame } > Jugar </button> </div > </div> </div >
                )
            }else if (list1.includes(gamename) && list3.includes(gamename)) {
                return ( <div id = { props.gamelist[idx].gameName }
                    className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 newgames popular' } >
                    <div className = 'single-game' >
                    <div class = "part-img" >

                    <img src = { returnImage(props.gamelist[idx].gameName) } alt = ""/>
                    < img className = "icon-img"
                    src = "assets/img/games/icon-1.png"
                    alt = "" />
                    </div> <div class = "part-text" >
                    <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                    name = { props.gamelist[idx].gameCode }
                    onClick = { CallGame } > Jugar </button> </div > </div> </div >
                )
            } else if (list3.includes(gamename)) {
                return ( <div id = { props.gamelist[idx].gameName }
                    className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 popular' } >
                    <div className = 'single-game' >
                    <div class = "part-img" >

                    <img src = { returnImage(props.gamelist[idx].gameName) }alt = ""/>
                    < img className = "icon-img" src = "assets/img/games/icon-1.png" alt = ""/>
                    </div> <div class = "part-text" >
                    <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                    name = { props.gamelist[idx].gameCode }
                    onClick = { CallGame } > Jugar </button> </div > </div> </div >
                )
            }
            return ( <div id = { props.gamelist[idx].gameName }className = { props.gamelist[idx].gameCategoryName + ' col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3' } >
                <div className = 'single-game' >
                <div class = "part-img" >

                <img src = { returnImage(props.gamelist[idx].gameName) }alt = ""/>
                <img className = "icon-img" src = "assets/img/games/icon-1.png" alt = ""/>
                </div> <div class = "part-text" >
                <h5 class = "game-title" > { props.gamelist[idx].gameName } <br/> { props.gamelist[idx].gameCategoryName } </h5> <button class = "def-btn def-small"
                name = { props.gamelist[idx].gameCode }
                onClick = { CallGame } > Jugar </button> </div > </div> </div >
            )
        })
    )
}
export default GameRow;