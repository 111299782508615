import React, {Component} from 'react';
import ListUser from '../../Components/ListUser/ListUser';
import PlayersService from '../../Services/players.service';


class Main extends Component {
    state ={
        users:[]
    }
    seeUsers = () =>{
        let { users } = this.state;
        var data = {
            userName: localStorage.getItem('userName'),
            token:localStorage.getItem('token')
        }
        PlayersService.getListUsers(data).then(response => {
            if(response.status === 201){
                users = response.data.users;
                this.setState({users});
            }
        }).catch(e => {
            //pass
        });
    }
    render(){
    const { users } = this.state;
    if(users.length === 0){
        this.seeUsers()
    }
    if(localStorage.getItem('languagePage')=== 'es'){
        return (
            <main>
                <div class="container">
                    <br></br>
                    <header>
                        <center><h1> Lista de Usuarios</h1></center>
                    </header>
                    <br></br>
                    <div className="block">
                        <br></br>
                        <br></br>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Usuario</th>
                                    <th>Email</th>
                                    <th>Nombre</th>
                                    <th>Tipo de usuario</th>
                                    <th>Agente</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                            <ListUser users={users}/>
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        );
    }else{
        return (
            <main>
                <div class="container">
                    <br></br>
                    <header>
                        <center><h1> Users List</h1></center>
                    </header>
                    <br></br>
                    <div className="block">
                        <br></br>
                        <br></br>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Email</th>
                                    <th>Name</th>
                                    <th>UserType</th>
                                    <th>Agent</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            <ListUser users={users}/>
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        );
    }
    }
}

export default Main;