import React, {Component} from 'react';
import AllTransactions from '../../Components/ListTransactions/AllTransactions';
import {TextField } from "@material-ui/core"
import {ValidatorForm} from "react-material-ui-form-validator";
import TransactionsService from '../../Services/Transactions.service';


class TransactionsMain extends Component {
    
    state ={
        radio: 'historic',
        user:{
            firstdate:'',
            seconddate:'',
            onlydate:'',
            userUserName:'',
            type:''
        },
        transactions:[]
    }

    displaydates = () =>{
        if (this.state.radio === 'period'){
            document.getElementById('dates period').style.display="";
            document.getElementById('single date').style.display="none";
        }else if (this.state.radio === 'specific'){
            document.getElementById('single date').style.display="";
            document.getElementById('dates period').style.display="none";
        }else{
            document.getElementById('dates period').style.display="none";
            document.getElementById('single date').style.display="none";
        }
    }
    componentDidUpdate(){
        this.displaydates()
    }
    handleChange = (event) =>{
        let { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    handleSubmit= () =>{

        let { transactions } = this.state;

        var data ={
            userName: localStorage.getItem('userName'),
            token:localStorage.getItem('token'),
            typeq: this.state.radio,
            firstdate: this.state.user.firstdate,
            seconddate: this.state.user.seconddate,
            onlydate: this.state.user.onlydate,
            userUserName: this.state.user.userUserName,
            type: this.state.user.type
        }
        TransactionsService.getAllTransactions(data).then(response =>{
            if(response.status === 201){
                transactions = response.data.transactions;
                this.setState({transactions});
            }
        })
    }

    render(){
    const { transactions } = this.state;
    if(localStorage.getItem("countant") === 0 || localStorage.getItem("countant") === undefined ){
        this.handleSubmit()
        localStorage.setItem("countant", 1)
    }
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    
    return (
        <main>
            <div class="container">
                <br></br>
                <header>
                    <center><h1> Reporte de Transacciones</h1></center>
                </header>
                <br/>
                <ValidatorForm
                        onError={errors => console.log(errors)}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}>
                    <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <label style={{display:'inline'}}><input type="radio" value="historic" checked={this.state.radio === 'historic'} onChange={(e) => {let { radio } = this.state; radio= e.target.value; this.setState({ radio }) }} /> Historico</label>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <label style={{display:'inline'}}><input type="radio" value="today" checked={this.state.radio === 'today'} onChange={(e) => {let { radio } = this.state; radio= e.target.value; this.setState({ radio }) }} /> Hoy</label>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <label style={{display:'inline'}}><input type="radio" value="yesterday" checked={this.state.radio === 'yesterday'} onChange={(e) => {let { radio } = this.state; radio= e.target.value; this.setState({ radio }) }} /> Ayer</label>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <label style={{display:'inline'}}><input type="radio" value="this month" checked={this.state.radio === 'this month'} onChange={(e) => {let { radio } = this.state; radio= e.target.value; this.setState({ radio }) }} /> Este mes</label>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <label style={{display:'inline'}}><input type="radio" value="last month" checked={this.state.radio === 'last month'} onChange={(e) => {let { radio } = this.state; radio= e.target.value; this.setState({ radio }) }} /> El mes pasado</label>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <label style={{display:'inline'}}><input type="radio" value="period" checked={this.state.radio === 'period'} onChange={(e) => {let { radio } = this.state; radio= e.target.value; this.setState({ radio }) }} /> Periodo de Fechas</label>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <label style={{display:'inline'}}><input type="radio" value="specific" checked={this.state.radio === 'specific'} onChange={(e) => {let { radio } = this.state; radio= e.target.value; this.setState({ radio }) }} /> Fecha espeifica</label>
                        </div>                  
                        <div id="dates period" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{display:'none'}} >
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <TextField
                                        id="date"
                                        label="Fecha de inicio"
                                        type="date"
                                        name="firstdate"
                                        value = {this.state.user.firstdate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{'max':today}}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <TextField
                                            id="date"
                                            label="Fecha de fin"
                                            type="date"
                                            name="seconddate"
                                            value = {this.state.user.seconddate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{'max':today}}
                                            variant="outlined"
                                            fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="single date" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{display:'none'}} >
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <TextField
                                            id="date"
                                            label="Fecha"
                                            type="date"
                                            name="onlydate"
                                            value = {this.state.user.onlydate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{'max':today}}
                                            variant="outlined"
                                            fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <TextField
                                id="outlined-select-currency-native"
                                select
                                name="type"
                                label=""
                                value={this.state.user.type}
                                SelectProps={{
                                    native: true,
                                }}
                                fullWidth
                                helperText="Tipo de transaccion"
                                variant="outlined"
                            >
                                <option key=""  value="">
                                    Todas
                                </option>
                                <option key="Withdraw"  value="Withdraw">
                                    Retiro
                                </option>
                                <option key="deposit"  value="deposit">
                                    Deposito
                                </option>
                            </TextField>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <button class="btn btn-success" type="submit">Aplicar</button>
                        </div>
                    </div>
                    <br/>
                </ValidatorForm>
                <div className="block">
                    <br></br>
                    <br></br>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Agente</th>
                                <th>Recibidor (para)</th>
                                <th>Monto</th>
                                <th>Fecha</th>
                                <th>Tipo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <AllTransactions transactions={transactions}/>
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
    }
}

export default TransactionsMain;