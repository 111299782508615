import http from "./http-common";

class ListGamesService {

    getListGames(data) {
        return http.post(`/GetGames`, data);
    }
    getListCategories(data){
        return http.post('/GetGameCategories', data)
    }
    
}

export default new ListGamesService();