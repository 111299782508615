
import React, {Component} from 'react';
import LogoutService from '../../Services/logout.service';
import Swal from 'sweetalert2'
import './Navbar.css'
import Logo from '../AdminBar/logo.png'
import ProfileService from '../../Services/profile.service';

class NavBar extends Component {

    state = {
        user:{
            userName:'',
            password:'',
            passwordconfirm: '',
            passwordnew:'',
            email:'',
            name:'',
            lastName:'',
            direction1:'',
            direction2:'',
            city:'',
            pc:'',
            state:'',
            phone:'',
            credits:'0'
        },
    }
    logout = () => {
        var data = {
            userName: localStorage.getItem('userName'),
            token:localStorage.getItem('token')
        }
        LogoutService.logout(data).then(response => {
            if(response.status === 201){
                localStorage.clear();
                Swal.fire({
                    title: 'Exito',
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                });
                window.location.reload()
            }else if(response.status === 200){
                localStorage.clear();
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }

        }).catch(e => {
            console.log(e)
            localStorage.clear()
            Swal.fire({
                title: 'Error',
                text: 'Verifica tus credenciales',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            window.location.reload()
        });
    }

    getProfileInfo = () => {
        let data ={
            usertoken: localStorage.getItem('token')
        }
        let {user} = this.state;
        ProfileService.getProfile(data).then(response =>{
            if(response.status === 201){
                user.userName = response.data.userName
                user.email = response.data.email
                user.name = response.data.name
                user.lastName = response.data.lastName
                user.direction1 = response.data.direction1
                user.direction2 = response.data.direction2
                user.city = response.data.city
                user.pc = response.data.pc
                user.state = response.data.state
                user.phone = response.data.phone
                user.credits = response.data.credits
                this.setState({ user });
            }else{

            }
        })
    }
render(){
    var {user} = this.state;

    if(user.userName.length === 0){
        this.getProfileInfo()
    }
    const mytoken = localStorage.getItem('token');
    if(mytoken === undefined || mytoken === null){
        return (
            <div class="header navbar-sticky">
                <div class="row">
                    <div class="overflow-hidden col-xl-3 col-lg-3 d-xl-flex d-lg-flex d-block align-items-center">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-7 d-xl-block d-lg-block">
                                <div class="logo">
                                    <a href="/games"><img src={Logo} alt="LOGO" className='logo-img'/></a>
                                </div>
                            </div>
                            <div class="d-xl-none d-lg-none col-5 d-flex align-items-center justify-content-end">
                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-9">
                        <div class="top-header">
                                <div class="row justify-content-end">
                                    <div class="col-xl-8 col-lg-8">
                                        <div class="top-right">
                                            <form class="part-searchBar">
                                            </form>
                                        </div>
                                        <div class="top-right">
                                            <div class="buttons">
                                                <a href="/registerMigration"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
  <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
</svg> Migrar mi cuenta</a>
                                            </div>
                                            <form class="part-searchBar">
                                            </form>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="bottom-header">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <nav class="navbar navbar-expand-lg navbar-light" id="navbar">
                                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul class="navbar-nav ml-auto">
                                            <li class="nav-item">
                                                <a class="nav-link" href="/games">Juegos</a>
                                            </li>
                                            
                                            
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Jugador
                                                </a>
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
                                                    <a class="dropdown-item" href="/registerMigration">Migrar mi cuenta</a>
                                                </div>
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                                                    <a class="dropdown-item" href="/login">Iniciar Sesión</a>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return(
            <div class="header navbar-sticky">
                <div class="row">
                    <div class="overflow-hidden col-xl-3 col-lg-3 d-xl-flex d-lg-flex d-block align-items-center">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-7 d-xl-block d-lg-block">
                                <div class="logo">
                                    <a href="/games"><img src={Logo} alt="LOGO" className='logo-img' /></a>
                                </div>
                            </div>
                            <div class="d-xl-none d-lg-none col-5 d-flex align-items-center justify-content-end">
                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-9">
                        <div class="top-header">
                                <div class="row justify-content-end">
                                    <div class="col-xl-8 col-lg-8">
                                        <div class="top-right">
                                            <form class="part-searchBar">
                                            <p className='welcome-tag'> Bienvenido {this.state.user.name} Tu crédito es de: {parseInt(this.state.user.credits).toFixed(2)} </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="bottom-header">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <nav class="navbar navbar-expand-lg navbar-light" id="navbar">
                                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav ml-auto">
                                            <li class="nav-item">
                                                <a class="nav-link" href="/games">Juegos</a>
                                            </li>
                                            
                                            
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Jugador
                                                </a>
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                                                    <a class="dropdown-item" href="/MyProfile">Mi perfil</a>
                                                    <button class="dropdown-item" onClick={this.logout} style={{background:'none', borderColor:'transparent'}}>Cerrar sesion</button>

                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}
}
export default NavBar;
