import http from "./http-common";

class SignupService {

    signupUser(data) {
        return http.post(`/signup`, data);
    }
    migrateUser(data) {
        return http.post(`/signupMigration`, data);
    }
    getListCategories(data) {
        return http.post('/GetGameCategories', data)
    }
    changepwd(data) {
        return http.post('/changepassword', data)
    }
    getAgents() {
        return http.get('/GetAgents')
    }
}

export default new SignupService();