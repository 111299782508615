import React, {Component} from 'react';
import ListUser from '../../Components/ListUser/ListUser';
import PlayersService from '../../Services/players.service';


class CashierListMain extends Component {
    state ={
        users:[]
    }
    seeUsers = () =>{
        let { users } = this.state;
        var data = {
            userName: localStorage.getItem('userName'),
            token:localStorage.getItem('token')
        }
        PlayersService.getCashiers(data).then(response => {
            if(response.status === 201){
                users = response.data.users;
                this.setState({users});
            }
        }).catch(e => {
            //pass
        });
    }
    render(){
    const { users } = this.state;
    if(users.length === 0){
        this.seeUsers()
    }
    return (
        <main>
            <div class="container">
                <br></br>
                <header>
                    <center><h1> Lista de Cajeros</h1></center>
                </header>
                <br></br>
                <div className="block">
                    <br></br>
                    <br></br>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Email</th>
                                <th>Nombre</th>
                                <th>Tipo de usuario</th>
                                <th>Agente</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                        <ListUser users={users}/>
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
    }
}

export default CashierListMain;