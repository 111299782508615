
   
import React, {Component} from 'react';
import GameNavBar from '../../Components/NavBar/GameNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import './game.css'

class Game extends Component{
    componentWillUnmount(){
        localStorage.setItem('game', undefined);
    }
    render(){
    const mytoken = localStorage.getItem('token');
    const game = localStorage.getItem('game');
    if(mytoken === undefined || mytoken === null || game === 'undefined'){
        window.location.replace('https://www.intergaming.com/games')
    }
    return (
    <body data-spy="scroll" data-target="#navbar" data-offset="0">
        <div class='row'>
            <div class="col-xl-12 col-lg-12 col-md-12" height="10%">
                <GameNavBar id="bar-game" style={{ position: 'absolute', zIndex: '7' }} />
                <iframe id="game" src={game} width="100%" frameborder="0" allowfullscreen title="Game" />
            </div>
        </div>
    </body>
    );}
}

export default Game;