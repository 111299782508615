import React, {Component} from 'react';
import {TextField } from "@material-ui/core"
import {ValidatorForm} from "react-material-ui-form-validator";
import PlayersService from '../../Services/players.service';
import ListIp from '../../Components/ListIp/ListIp';
import Swal from 'sweetalert2'
//import transitions from '@material-ui/core/styles/transitions';
import ListTransactions from '../../Components/ListTransactions/ListTransactions';

class PMain extends Component {



    state = {
        user:{
            userName:'',
            name:'',
            email:'',
            phone:'',
            uType:'',
            credit:'',
            utoken:''
        },
        datatrans:{
            deposit:'',
            withdraw:''
        },
        ips:[],
        transactions:[]
    }
    getPlayerData = (iduser) =>{
        var data = {
            //userName: localStorage.getItem('userName'),
            usertoken: iduser
        }
        const { user } = this.state;
        let { ips } = this.state;
        let { transactions } = this.state;

        PlayersService.getUserDetails(data).then(response => {
            if(response.status === 201){
                user.userName = response.data.userName
                user.name = response.data.name
                user.email = response.data.email
                user.phone = response.data.phone
                user.uType = response.data.uType
                user.credit = response.data.credit
                user.utoken = iduser
                ips = response.data.ips
                transactions = response.data.transactions

                this.setState({user});
                this.setState({ips});
                this.setState({transactions});

            }
        }).catch(e => {
            //pass
        });
    }
    handleChange = (event) =>{
        let { datatrans } = this.state;
        datatrans[event.target.name] = event.target.value;
        this.setState({ datatrans });
    }

    handleDeposit = () =>{
        let { datatrans } = this.state;
        let { user } = this.state;

        var transaction = {
            userName: localStorage.getItem('userName'),
            token:localStorage.getItem('token'),
            deposit: datatrans.deposit,
            userToken: user.utoken
        }
        if(transaction.deposit!== '' && transaction.deposit!== undefined && transaction.deposit >=0 ){
            PlayersService.AddCredit(transaction).then(response => {
                if(response.status === 201){
                    Swal.fire({
                        title: 'Deposito exitoso',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    window.location.reload();
                }else{
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                }
            }).catch(e => {
                localStorage.clear();
                Swal.fire({
                    title: 'Error',
                    text: 'Verifica tus credenciales',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                window.location.reload()
            });
        }
    }

    handleWithdraw= () =>{
        let { datatrans } = this.state;
        let { user } = this.state;

        var transaction = {
            userName: localStorage.getItem('userName'),
            token:localStorage.getItem('token'),
            withdraw: datatrans.withdraw,
            userToken: user.utoken
        }
        if(transaction.withdraw!== '' && transaction.withdraw!== undefined && transaction.withdraw >=0 ){
            PlayersService.WithdrawCredit(transaction).then(response => {
                if(response.status === 201){
                    Swal.fire({
                        title: 'Retiro exitoso',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    window.location.reload();
                }else{
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                }
            }).catch(e => {
                localStorage.clear()
                Swal.fire({
                    title: 'Error',
                    text: 'Verifica tus credenciales',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                window.location.reload()
            });
        }
    }

    render(){
        const  iduser = this.props.iduser
        const { user } = this.state;
        const { datatrans } = this.state;
        let {ips} = this.state;
        let {transactions} = this.state;
        console.log(iduser)
        if(user.name === ''){
            this.getPlayerData(iduser)
        }
        return (
            <main>
                <div class="container">
                    <br></br>
                    <header className="d-flex justify-content-center">
                        <h1> Usuario</h1>
                    </header>
                    <br></br>
                    <div className="block">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                Usuario: {user.userName}
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                Correo: {user.email}
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                Nombre:  {user.name}
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                Telefono: {user.phone}
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                Tipo de usuario:  {user.uType}
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                Creditos:  {user.credit.toLocaleString()}
                            </div>
                        </div>
                            <br></br>
                            <br></br>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                <h5>Agregar Fondos</h5>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                <h5>Retirar Fondos</h5>
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                            <ValidatorForm
                                onError={errors => console.log(errors)}
                                onChange={this.handleChange}
                                onSubmit={this.handleDeposit}>
                                    <TextField
                                            label="Cantidad"
                                            name="deposit"
                                            type="number"
                                            variant="outlined"
                                            inputProps={{min:"1"}}

                                            value={datatrans.deposit}
                                        />
                                    <br/>
                                    <br/>
                                    <button className="btn btn-primary" type="submit">Agregar</button>
                                </ValidatorForm>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                            <ValidatorForm
                                onError={errors => console.log(errors)}
                                onChange={this.handleChange}
                                onSubmit={this.handleWithdraw}>
                                    <TextField
                                            label="Cantidad"
                                            name="withdraw"
                                            inputProps={{min:"1"}}
                                            type="number"
                                            variant="outlined"
                                            value={user.withdraw}
                                        />
                                    <br/>
                                    <br/>
                                    <button className="btn btn-primary" type="submit">Retirar</button>
                            </ValidatorForm>
                            </div>
                        </div>
                            <br></br>
                            <br></br>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                <div class="row">
                                        <h5>Log de Ingresos </h5>
                                </div>
                                <table class="table">
                                <thead>
                                    <tr>
                                        <th>IP</th>
                                        <th>Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ListIp ips={ips}/>
                                </tbody>
                            </table>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 col-xl-6">
                                <div class="row">
                                        <h5>Log de Transacciones </h5>
                                </div>
                                <table class="table">
                                <thead>
                                    <tr>
                                        <th>Monto</th>
                                        <th>Hora</th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ListTransactions transactions={transactions}/>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </main>
        );
    }
};

export default PMain;