import React from "react";


const PlayCheckList = (props) => {
    return (
        props.users.map((val, idx) => {
            //let urlId = `url-${idx}`;
            return ( <
                tr >
                <
                td > { props.users[idx].userName } < /td> <
                td > { props.users[idx].email } < /td> <
                td > { props.users[idx].name } { props.users[idx].lastName } < /td> <
                td > { props.users[idx].phone } < /td> <
                td > < a href = { props.users[idx].url }
                target = "_blank" > Ver jugadas < /a></td >
                <
                /tr>
            )
        })
    )
}
export default PlayCheckList;