import React from "react";
const ListTransactions = (props) => {
return (
    props.transactions.map((val, idx)=> {
    var myDate = new Date(props.transactions[idx].date);
    return (
        <tr>
            <td>{props.transactions[idx].amount}</td>
            <td>{myDate.toLocaleString('es-MX')}</td>
            <td>{props.transactions[idx].type}</td>

        </tr>
        )
    })
)
}
export default ListTransactions;