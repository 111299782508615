import { Component } from "react";
import NavBar from "../../Components/NavBar/NavBar";
import {ValidatorForm} from "react-material-ui-form-validator";
import {TextField } from "@material-ui/core"
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Swal from 'sweetalert2'
import 'react-phone-input-2/lib/bootstrap.css'
import SignupService from "../../Services/signup.service";
import ProfileService from "../../Services/profile.service";
import Footer from "../../Components/Footer/Footer";

class Signup extends Component{
    state = {
        user:{
            userName:'',
            password:'',
            passwordconfirm: '',
            passwordnew:'',
            email:'',
            name:'',
            lastName:'',
            direction1:'',
            direction2:'',
            city:'',
            pc:'',
            state:'',
            phone:'',
            credits:'0'
        },
        values:{
            showPassword: false,
        },
    }
    
    handleChange = (event) =>{
        let { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }
    handleSubmit = () =>{
        const data = {
            usertoken : localStorage.getItem('token'),
            password: this.state.user.password,
            passwordnew: this.state.user.passwordnew
        }
        if(this.state.user.passwordnew !== this.state.user.passwordconfirm){
            Swal.fire({
                title: 'Las contraseñas no coinciden',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }else if(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(data.passwordnew)){
            SignupService.changepwd(data).then(response =>{
                if(response.status === 201){
                    Swal.fire({
                        title: 'Contraseña cambiada satisfactoriamente',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                }else{
                    Swal.fire({
                        title: 'La contraseña anterior que ingreso es incorrecta',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                }
            })
        }else{
            Swal.fire({
                title: 'Contraseña muy insegura',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    }

    componentDidMount(){
        ValidatorForm.addValidationRule('isStrongPassword', (value) =>{
            if(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(value)){
                return true;
            }else{
                return false;
            }
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value === this.state.user.password) {
                return true;
            }else{
                return false;
            }
        });
        
    }
    handleClickShowPassword = () => {
        const { values } = this.state;
        values.showPassword = !values.showPassword;
        this.setState({ values });
    }

    getProfileInfo = () => {
        let data ={
            usertoken: localStorage.getItem('token')
        }
        let {user} = this.state;
        ProfileService.getProfile(data).then(response =>{
            if(response.status === 201){
                user.userName = response.data.userName
                user.credits = response.data.credits
                this.setState({ user });
            }else{

            }
        })
    }
    
    render(){
        var {user} = this.state;
        var {values} = this.state;
        const theme = createMuiTheme({
            palette: {
                type: "dark",
                primary: {
                    main: '#fafafa',
                },
                secondary: {
                    main: '#fafafa',
                },
            },
        });
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear()-18;

        if(user.userName.length === 0){
            this.getProfileInfo()
        }
        today = yyyy + '-' + mm + '-' + dd;
        return(
            <body>
                <NavBar/>
                <div class="register">
                <div class="container">
                    <div class="reg-body">
                        <ValidatorForm
                        onError={errors => console.log(errors)}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <h4 class="sub-title">Mi Perfil</h4>
                                    <br/>
                                    <MuiThemeProvider theme={theme}>
                                    <div class="term-condition">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6">
                                                <p>
                                                    Usuario: <h5 style={{display:'inline'}}> {user.userName}</h5>
                                                </p>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6">
                                                <p>
                                                    Email: <h5 style={{display:'inline'}}>{user.email}</h5>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6">
                                                <p>
                                                    Creditos:  <h5 style={{display:'inline'}}>{user.credits.toLocaleString()}</h5>
                                                </p>
                                            </div>
                                        </div>
                                    </div>                          
                                    </MuiThemeProvider>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 additional-info">
                                    <h4 class="sub-title">Cambiar mi Contraseña</h4>
                                    <br/>
                                    <MuiThemeProvider theme={theme}>
                                        <FormControl fullWidth required  variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Contraseña actual</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={user.password}
                                                fullWidth
                                                required
                                                name="password"
                                                inputProps={{minlength:"8"}}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    edge="end"
                                                    >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                labelWidth={140}
                                            />
                                        </FormControl>
                                        <br/>
                                        <br/>
                                    </MuiThemeProvider>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            required
                                            label="Nueva contraseña"
                                            name="passwordnew"
                                            type={values.showPassword ? 'text' : 'password'}
                                            validators={['required', 'isPassWordMatch']}
                                            errorMessages={['Este campo es obligatorio', 'Las contraseñas no coinciden']}
                                            variant="outlined"
                                            value={user.passwordnew}
                                            fullWidth
                                        />
                                        <br/>
                                        <br/>
                                        <TextField
                                            required
                                            label="Confirma la nueva contraseña"
                                            name="passwordconfirm"
                                            type={values.showPassword ? 'text' : 'password'}
                                            validators={['required', 'isPassWordMatch']}
                                            errorMessages={['Este campo es obligatorio', 'Las contraseñas no coinciden']}
                                            variant="outlined"
                                            value={user.passwordconfirm}
                                            fullWidth
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                            <br/>
                            <button class="def-btn btn-form" type="submit">Cambiar mi contraseña </button>
                            
                        </ValidatorForm>
                    </div>
                </div>
            </div>
            <Footer />
            </body>
        );
    }
}
export default Signup;