
import React from 'react';
import { Link } from 'react-router-dom';
//import LogoutService from '../../Services/logout.service';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/scss/styles.scss';
import Logo from './logo.png'
import { FaUser, FaNetworkWired, FaCoins, FaGamepad } from 'react-icons/fa';
//import './App.scss';
import {FaBars } from 'react-icons/fa';



const AdminBar = ({ toggled, handleToggleSidebar }) => {

    if(localStorage.getItem('languagePage')=== 'es'){
        return (
            <div>
        <div className="btn-toggle" style={{position:"fixed", marginLeft:"1rem", marginTop:"1rem"}} onClick={() => handleToggleSidebar(true)} >
            <FaBars size="30" />
        </div>
        <ProSidebar
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
        >
            <SidebarHeader>
                <img src={Logo} alt="Logo" />
            </SidebarHeader>
    
            <SidebarContent>
                <Menu iconShape="circle">
                    <SubMenu title="Usuarios" icon={<FaUser />}>
                        <MenuItem ><Link to="/AdminModules/UserList"/>Lista de usuarios</MenuItem>
                        <MenuItem> <Link to="/AdminModules/MasterAgentList"/>Lista de Master Agent</MenuItem>
                        <MenuItem><Link to="/AdminModules/AgentList"/> Lista de agentes</MenuItem>
                        <MenuItem><Link to="/AdminModules/CashierList"/>Lista de cajeros</MenuItem>
                        <MenuItem> <Link to="/AdminModules/PlayerList"/>Lista de jugadores</MenuItem>
                    </SubMenu>
                    <MenuItem icon={<FaNetworkWired/>} ><Link to="/AdminModules/IPReport"/>IP Log</MenuItem>
                    <SubMenu title="Reportes Financieros" icon={<FaCoins />}>
                        <MenuItem><Link to="/AdminModules/Transactions"/>Transacciones</MenuItem>
                    </SubMenu>
                    <SubMenu title="Reportes de juego" icon={<FaGamepad />}>
                        <MenuItem ><Link to="/AdminModules/LocationReport"/>Lista de locaciones</MenuItem>
                        <MenuItem ><Link to="/AdminModules/PlayCheck"/>Verificar jugadas</MenuItem>
                    </SubMenu>

                </Menu>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <button onClick={()=>localStorage.setItem('languagePage','es')} className='btn btn-primary'>Español</button>
                <button onClick={()=>localStorage.setItem('languagePage','en')} className='btn btn-primary m-5'>English</button>
            </SidebarContent>
            <SidebarFooter style={{ textAlign: 'center' }}>

            </SidebarFooter>
        </ProSidebar>
        </div>
            );
    }else{
        return (
            <div>
        <div className="btn-toggle" style={{position:"fixed", marginLeft:"1rem", marginTop:"1rem"}} onClick={() => handleToggleSidebar(true)} >
            <FaBars size="30" />
        </div>
        <ProSidebar
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
        >
            <SidebarHeader>
                <img src={Logo} alt="Logo" />
            </SidebarHeader>
    
            <SidebarContent>
                <Menu iconShape="circle">
                    <SubMenu title="Users" icon={<FaUser />}>
                        <MenuItem ><Link to="/AdminModules/UserList"/>User List</MenuItem>
                        <MenuItem> <Link to="/AdminModules/MasterAgentList"/>Master Agent List</MenuItem>
                        <MenuItem><Link to="/AdminModules/AgentList"/> Agent List</MenuItem>
                        <MenuItem><Link to="/AdminModules/CashierList"/>Casher List</MenuItem>
                        <MenuItem> <Link to="/AdminModules/PlayerList"/>Gamer List</MenuItem>
                    </SubMenu>
                    <MenuItem icon={<FaNetworkWired/>} ><Link to="/AdminModules/IPReport"/>IP Log</MenuItem>
                    <SubMenu title="Financial Reports" icon={<FaCoins />}>
                        <MenuItem><Link to="/AdminModules/Transactions"/>Transactions</MenuItem>
                    </SubMenu>
                    <SubMenu title="Game Reports" icon={<FaGamepad />}>
                        <MenuItem ><Link to="/AdminModules/LocationReport"/>Location List</MenuItem>
                        <MenuItem ><Link to="/AdminModules/PlayCheck"/>Play CheckList</MenuItem>
                    </SubMenu>

                </Menu>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <button onClick={()=>localStorage.setItem('languagePage','es')} className='btn btn-primary'>Español</button>
                <button onClick={()=>localStorage.setItem('languagePage','en')} className='btn btn-primary m-5'>English</button>
            </SidebarContent>
            <SidebarFooter style={{ textAlign: 'center' }}>

            </SidebarFooter>
        </ProSidebar>
        </div>
            );
    }
 };

export default AdminBar;
