import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
// eslint-disable-next-line
import $ from 'jquery';
// eslint-disable-next-line
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Complements/css/animate.css'
import './Complements/css/responsive.css'
import './Complements/css/style.css'

import React, { Component } from 'react';
import { render } from 'react-dom';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Games from './Pages/Games/Games';
import Game from './Pages/Game/Game';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import SignupMigration from './Pages/Signup/SignupMigration';
import UserList from './Pages/UserList/UserList';
import Player from './Pages/Player/Player';
import UserProfile from './Pages/UserProfile/UserProfile';
import PlayerList from './Pages/PlayerList/PlayerList';
import CashierList from './Pages/CashierList/CashierList';
import AgentList from './Pages/AgentList/AgentList';
import Cashier from './Pages/Cashier/Cashier';
import CasinoEarnings from './Pages/CasinoEarnings/CasinoEarnings';
import CreditTransfer from './Pages/CreditTransfer/CreditTransfer';
import NetworkBalance from './Pages/NetworkBalance/NetworkBalance';
import PlayCheck from './Pages/PlayCheck/PlayCheck';
import WinAnalysis from './Pages/WinAnalysis/WinAnalysis';
import MasterAgentList from './Pages/MasterAgentList/MasterAgentList';
import IPreport from './Pages/IPReport/IPreport';
import Transactions from './Pages/Transactions/Transactions';
import Locationreport from './Pages/LocationReport/LocationReport';

class App extends Component {
    render() {
        return ( <
            Router >
            <
            Route exact path = "/"
            component = { Games }
            /> <
            Route exact path = "/games"
            component = { Games }
            /> <
            Route exact path = "/game"
            component = { Game }
            /> <
            Route exact path = "/login"
            component = { Login }
            /> <
            Route exact path = "/register"
            component = { Signup }
            />  <
            Route exact path = "/registerMigration"
            component = { SignupMigration }
            /> <
            Route exact path = "/AdminModules/UserList"
            component = { UserList }
            /> <
            Route exact path = "/AdminModules/PlayerList"
            component = { PlayerList }
            /> <
            Route exact path = "/AdminModules/CashierList"
            component = { CashierList }
            /> <
            Route exact path = "/AdminModules/AgentList"
            component = { AgentList }
            /> <
            Route exact path = "/AdminModules/MasterAgentList"
            component = { MasterAgentList }
            /> <
            Route exact path = "/AdminModules/Player/:id"
            render = {
                (props) => < Player {...props }
                />}/ >
                <
                Route exact path = "/MyProfile"
                component = { UserProfile }
                /> <
                Route exact path = "/AdminModules/Cashier"
                component = { Cashier }
                /> <
                Route exact path = "/AdminModules/CasinoEarnings"
                component = { CasinoEarnings }
                /> <
                Route exact path = "/AdminModules/CreditTransfer"
                component = { CreditTransfer }
                /> <
                Route exact path = "/AdminModules/NetworkBalance"
                component = { NetworkBalance }
                /> <
                Route exact path = "/AdminModules/WinAnalysis"
                component = { WinAnalysis }
                /> <
                Route exact path = "/AdminModules/IPReport"
                component = { IPreport }
                /> <
                Route exact path = "/AdminModules/LocationReport"
                component = { Locationreport }
                /> <
                Route exact path = "/AdminModules/Transactions"
                component = { Transactions }
                /> <
                Route exact path = "/AdminModules/PlayCheck"
                component = { PlayCheck }
                /> < /
                Router >
            )
        }
    }
    render( < App / > , document.getElementById('root'));