import http from "./http-common";

class TransactionsService {

    getAllTransactions(data) {
        return http.post(`/gettranslog`, data);
    }
    
}

export default new TransactionsService();