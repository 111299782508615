import http from "./http-common";


class UsersIPS {
    IPList(data){
        return http.post(`/getIPlog`, data);
    }
    LocationList(data){
        return http.post(`/getLocationlog`, data);
    }
}
export default new  UsersIPS();