import React, {Component} from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import breadcrumb from '../../Complements/img/breadcrumb.png'
import { MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {TextField } from "@material-ui/core"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2'
import LoginService from '../../Services/login.service';
import IpService from '../../Services/ip.service';
import Footer from '../../Components/Footer/Footer';


class Login extends Component {
    state = {
        user:{
            userName:'',
            password:'',
        },
        values:{
            showPassword: false,
        },
    }
    
    


    handleChange = (event) =>{
        let { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }
    handleClickShowPassword = () => {
        const { values } = this.state;
        values.showPassword = !values.showPassword;
        this.setState({ values });
    }

    ipfunc = () =>{
        IpService.getIP().then(response => {
            var ip =response.data
            console.log(ip)
        })
    }

    handleSubmit = () =>{
        let { user } = this.state;
        IpService.getIP().then(response => {
            var ip =response.data
            console.log(ip)
            var userdata = {
                userName: user.userName,
                password: user.password,
                CAPTCHA: user.CAPTCHA,
                ip: ip
            }
            var letterNumber = /^[0-9a-zA-Z]+$/;
            if(user.userName.match(letterNumber)){
                LoginService.login(userdata).then(response => {
                    if(response.status === 201){
                        Swal.fire({
                            title: 'Inicio de sesión exitoso',
                            icon: 'success',
                            confirmButtonText: 'Aceptar'
                        });
                        localStorage.setItem('token', response.data.token)
                        localStorage.setItem('userName', response.data.userName)
                        localStorage.setItem('agent', response.data.agent)
                        window.location.reload()
                    }else if(response.status === 200){
                        Swal.fire({
                            title: 'Error',
                            text: response.data.message,
                            icon: 'error',
                            confirmButtonText: 'Aceptar'
                        });
                    }
                }).catch(e => {
                    console.log(e)
                    localStorage.clear()
                    Swal.fire({
                        title: 'Error',
                        text: 'Verifica tus credenciales',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                    window.location.reload()

                });
            }else{
                Swal.fire({
                    title: 'Tu nombre de usuario solo puede contener letras y numeros',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        })

    }

    render(){
    let {user} = this.state;
    let {values} = this.state;
    this.ipfunc()
    const theme = createMuiTheme({
        palette: {
            type: "dark",
            primary: {
                main: '#fafafa',
            },
            secondary: {
                main: '#fafafa',
            },
        },
    });
    const mytoken = localStorage.getItem('token');
    if(mytoken === undefined || mytoken === null){
        //pass
    }else{
        
        window.location.replace('https://www.intergaming.com/games')
    }

    localStorage.setItem('languagePage','es');
    return (
        <body data-spy="scroll" data-target="#navbar" data-offset="0">
            <NavBar/>
            <div class="breadcrumb-miscoo">
            <div class="breadcrumb-img">
                <img src={breadcrumb} alt=""/>
                <img src={breadcrumb} alt=""/>
                <img src={breadcrumb} alt=""/>
                <img src={breadcrumb} alt=""/>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-6">
                        <div class="breadcrumb-content">
                            <h2 class="title">Login to enter</h2>
                            <ul>
                                <li>
                                    <a href="/games">Home</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="register login-page">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-6 col-md-8">
                        <MuiThemeProvider theme={theme}>
                            <div class="reg-body">
                                <ValidatorForm
                                onError={errors => console.log(errors)}
                                onChange={this.handleChange}
                                onSubmit={this.handleSubmit}
                                >
                                    <h4 class="sub-title">Ingresa a tu cuenta</h4>
                                    <TextField
                                        required
                                        label="Nombre de usuario"
                                        name="userName"
                                        style={{'borderBottom':'none'}}
                                        type="text"
                                        validators={['required']}
                                        errorMessages={['Este campo es obligatorio']}
                                        variant="outlined"
                                        value={user.userName}
                                        fullWidth
                                    />
                                    <br/>
                                    <br/>
                                    <FormControl fullWidth required  variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={user.password}
                                            fullWidth
                                            required
                                            name="password"
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                edge="end"
                                                >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            labelWidth={95}
                                        />
                                    </FormControl>
                                    <br/>
                                    <br/>                                    
                                    <button class="btn-form def-btn">Login</button>
                                </ValidatorForm>
                            </div>
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        </div>
        <div class="cta">
            <div class="container">
                <div class="row justify-content-xl-between justify-content-lg-between justify-content-sm-center">
                    <div class="col-xl-7 col-lg-7 col-md-8 col-sm-10">
                    <h2>E-GAMES - Si comes, duermes y respiras juegos en línea como nosotros, <br/> has venido al lugar correcto.</h2>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-10">
                        <a href="#0" class="def-btn play-now-btn">Jugar ahora</a>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </body>
        );
    }
    }
    export default Login;