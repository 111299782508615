import React, { useState } from 'react';
import AdminBar from '../../Components/AdminBar/AdminBar';
import LocationreportMain from './LocationReport.Main';

function Locationreport({ setLocale }) {
    const [toggled, setToggled] = useState(false);


const handleToggleSidebar = (value) => {
    setToggled(value);
};

    return (
        <div class="row" >
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 col-xl-3" >
                <AdminBar
                    toggled={toggled}
                    handleToggleSidebar={handleToggleSidebar}
                />
            </div>
            <br/>
            <br/>
            <div class="col-md-9" >
                <LocationreportMain
                    toggled={toggled} 
                    handleToggleSidebar={handleToggleSidebar}         
                />
            </div>
        </div>
    );
}

export default Locationreport