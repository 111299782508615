import http from "./http-common";

class ProfileService {

    getProfile(data) {
        return http.post(`/getuserprofile`, data);
    }
    
}

export default new ProfileService();