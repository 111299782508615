import { Component } from "react";
import NavBar from "../../Components/NavBar/NavBar";
import {ValidatorForm} from "react-material-ui-form-validator";
import {MenuItem, Select, TextField } from "@material-ui/core"
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Swal from 'sweetalert2'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import SignupService from "../../Services/signup.service";
import Footer from "../../Components/Footer/Footer";

class Signup extends Component{
    state = {
        user:{
            userName:'',
            password:'',
            passwordconfirm: '',
            email:'',
            name:'',
            lastName:'',
            direction1:'',
            direction2:'',
            city:'',
            pc:'',
            state:'',
            phone:'',
            check:false,
            agent: '',
        },
        values:{
            showPassword: false,
        },
        estados:[{value: 'Selecciona tu estado'},{value: 'Aguascalientes'},{value: 'Baja California'},{value: 'Baja California Sur'},{value: 'Campeche'},{value: 'Chiapas'},{value: 'Chihuahua'},{value: 'Coahuila'},{value: 'Colima'},{value: 'Ciudad de México / Distrito Federal'},{value: 'Durango'},{value: 'Estado de México'},{value: 'Guanajuato'},{value: 'Guerrero'},{value: 'Hidalgo'},{value: 'Jalisco'},{value: 'Michoacán'},{value: 'Morelos'},{value: 'Nayarit'},{value: 'Nuevo León'},{value: 'Oaxaca'},{value: 'Puebla'},{value: 'Querétaro'},{value: 'Quintana Roo'},{value: 'San Luis Potosí'},{value: 'Sinaloa'},{value: 'Sonora'},{value: 'Tabasco'},{value: 'Tamaulipas'},{value: 'Tlaxcala'},{value: 'Veracruz'},{value: 'Yucatán'},{value: 'Zacatecas'}],
        agents: []
    }
    
    handleChange = (event) =>{
        let { user } = this.state;
        user[event.target.name] = event.target.value;
        console.log(event.target.value)
        this.setState({ user });
    }
    handleSubmit = () =>{
        let { user } = this.state;
        var userdata = {
            userName: user.userName,
            password: user.password,
            passwordconfirm: user.passwordconfirm,
            email: user.email,
            name: user.name,
            lastName: user.lastName,
            direction1: user.direction1,
            direction2: user.direction2,
            city: user.city,
            pc: user.pc,
            state: user.state,
            phone: user.phone,
            CAPTCHA: user.CAPTCHA,
            agent: user.agent
        }

        var letterNumber = /^[0-9a-zA-Z]+$/;
            if(user.userName.match(letterNumber)){
                if(user.password !== user.passwordconfirm){
                    Swal.fire({
                        title: 'Las contraseñas no coinciden',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                }else if(userdata.phone.charAt(0)!=="+" && userdata.phone.charAt(0)!=="5" && userdata.phone.charAt(0)!=="2"){
                    console.log(userdata.phone)
                    console.log(userdata.phone.charAt(0))
                    console.log(userdata.phone.charAt(1))
                    console.log(userdata.phone.charAt(2))

                    Swal.fire({
                        title: 'Tu telefono debe empezar con +52',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                }else if(user.phone.length!==16){
                    Swal.fire({
                        title: 'Su telefono debe ser de 10 dígitos',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                }else if(true){
                        SignupService.signupUser(userdata).then(response => {
                            if(response.status === 201){
                                Swal.fire({
                                    title: 'Usuario registrado correctamente',
                                    icon: 'success',
                                    confirmButtonText: 'Aceptar'
                                });
                            }else if(response.status === 200){
                                Swal.fire({
                                    title: 'Error',
                                    text: response.data.message,
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar'
                                });
                            }
                        }).catch(e => {
                            console.log(e)
                            Swal.fire({
                                title: 'Error',
                                text: 'Tus datos ya están en uso',
                                icon: 'error',
                                confirmButtonText: 'Aceptar'
                            });
                        });
                }else{
                    Swal.fire({
                        title: 'Tu contraseña es muy insegura, asegurate de que debe tener al menos 8 caracteres e incluir por lo menos: 1 número, 1 letra minúscula y 1 letra mayúscula.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                }
            }else{
                Swal.fire({
                    title: 'Tu nombre de usuario solo puede contener letras y numeros',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        
        
        
    }


    handleClickShowPassword = () => {
        const { values } = this.state;
        values.showPassword = !values.showPassword;
        this.setState({ values });
    };
    

    AgentsList (){
        SignupService.getAgents().then(response => {
            if(response.status === 201){
                let {agents} = this.state;
                agents = response.data.agents;
                console.log(response.data)
                this.setState({ agents });
            }else if(response.status === 200){
            
            }
        }).catch(e => {
            
        });
    }

    render(){
        if(this.state.agents.length <= 0){
            this.AgentsList();
        }
        let {user} = this.state;
        let {values} = this.state;
        const theme = createMuiTheme({
            palette: {
                type: "dark",
                primary: {
                    main: '#fafafa',
                },
                secondary: {
                    main: '#fafafa',
                },
            },
        });
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear()-18;
        let {estados} = this.state;

        today = yyyy + '-' + mm + '-' + dd;
        return(
            <body>
                <NavBar/>
                <div class="register">
                <div class="container">
                    <div class="reg-body">
                        <ValidatorForm
                        onError={errors => console.log(errors)}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                        >
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h4 class="sub-title">Información de la cuenta</h4>
                                    <br/>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            required
                                            label="Nombre de usuario"
                                            name="userName"
                                            style={{'borderBottom':'none'}}
                                            type="text"
                                            validators={['required']}
                                            errorMessages={['Este campo es obligatorio']}
                                            variant="outlined"
                                            value={user.userName}
                                            fullWidth
                                        />
                                        <br/>
                                        <br/>
                                        <FormControl fullWidth required  variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={user.password}
                                                fullWidth
                                                required
                                                name="password"
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    edge="end"
                                                    >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                labelWidth={95}
                                            />
                                        </FormControl>
                                        <br/>
                                        <br/>
                                        <TextField
                                            required
                                            label="Confirma tu contraseña"
                                            name="passwordconfirm"
                                            type={values.showPassword ? 'text' : 'password'}
                                            validators={['required', 'isPassWordMatch']}
                                            errorMessages={['Este campo es obligatorio', 'Las contraseñas no coinciden']}
                                            variant="outlined"
                                            value={user.passwordconfirm}
                                            fullWidth
                                        />
                                        <br/>
                                        <br/>
                                        <TextField
                                            required
                                            label="Correo electrónico"
                                            name="email"
                                            type="email"
                                            validators={['required']}
                                            errorMessages={['Este campo es obligatorio']}
                                            variant="outlined"
                                            value={user.email}
                                            fullWidth
                                        />
                                        <br/>
                                        <br/>
                                        <h5>Elige a tu agente</h5>
                                        <br />
                                        <Select
                                            labelId="agent"
                                            id="agent"
                                            name="agent"
                                            value={user.agent}
                                            validators={['required']}
                                            label="Usuario de Agente"
                                            errorMessages={['Este campo es obligatorio']}
                                            fullWidth
                                            onChange={this.handleChange}
                                        >
                                            {
                                                this.state.agents.map((val, idx)=> {
                                                    return (
                                                        <MenuItem value={this.state.agents[idx]}>{this.state.agents[idx]}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </MuiThemeProvider>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 additional-info">
                                    <h4 class="sub-title">Información personal</h4>
                                    <br/>
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            required
                                            label="Nombre"
                                            name="name"
                                            type="text"
                                            validators={['required', 'isName']}
                                            errorMessages={['Este campo es obligatorio', 'Formato incorrecto']}
                                            variant="outlined"
                                            value={user.name}
                                            fullWidth
                                        />
                                        <br/>
                                        <br/>
                                        <TextField
                                            required
                                            label="Apellido"
                                            name="lastName"
                                            type="text"
                                            validators={['required', 'isName']}
                                            errorMessages={['Este campo es obligatorio', 'Formato incorrecto']}
                                            variant="outlined"
                                            value={user.lastName}
                                            fullWidth
                                        />
                                        <br/>
                                        <br/>
                                        <label style={{color:'#999999', fontSize:'15px', marginBottom:'10px'}}>Teléfono celular</label>
                                        <PhoneInput
                                            style={{marginTop:'-13px'}}
                                            label="Celular"
                                            enableSearch="true"
                                            inputStyle={{width:'100%', height:'3rem', background:'none', color:'#fafafa', borderColor:'#999999'}}
                                            placeholder=""
                                            inputProps={{name:'phone'}}
                                            country={'mx'}
                                            value={user.phone}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            {/* <div class="term-condition">
                                <h4 class="title">Terms and Conditions</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat ipsum dolor sit
                                    amet, consectetur adipiscing elit, sed do eiusmod tempor.
                                    <br/>
                                    <br/>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat ipsum dolor sit
                                    amet, consectetur adipiscing elit, sed do eiusmod tempor.
                                </p>
                            </div> */}
                            <div class="row">
                                
                                {/* <div class="col-xl-6 col-lg-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="check" id="exampleRadios5" checked={user.check}/>
                                        <label class="form-check-label" for="exampleRadios5">
                                            Acepto los terminos y condiciones.
                                        </label>
                                    </div>
                                </div> */}
                                <div class="col-xl-6 col-lg-6 col-sm-6 col-xs-6"></div>
                                <div class="col-xl-6 col-lg-6 col-sm-6 col-xs-6">
                                    <button class="def-btn btn-form" type="submit" onSubmit={this.handleSubmit}>Registrarme </button>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
            <Footer />
            </body>
        );
    }
}
export default Signup;