import React from "react";
const ListIp = (props) => {
return (
    props.ips.map((val, idx)=> {
    var myDate = new Date(props.ips[idx].Ingreso);

    return (
        <tr>
            <td>{props.ips[idx].ip}</td>
            <td>{myDate.toLocaleString('es-MX')}</td>
        </tr>
        )
    })
)
}
export default ListIp;